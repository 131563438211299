.styles-text-field-label {
    font-size: 14px !important;
}

.styles-text-field>.MuiFilledInput-root {
    font-size: 14px !important;
    background-color: #E9E9E9 !important;
    border-radius: 8px !important;
    border: 1px solid #d5d5d5;
}

.styles-text-field>.MuiFilledInput-root::before {
    border: none !important;
}

.styles-text-field>.MuiFilledInput-root::after {
    border: none !important;
}

.styles-text-field-label-small {
    font-size: 14px !important;
}