@mixin page_title {
  --page-title-font-size: var(--sjs-font-pagetitle-size, calc(1.5 * (var(--sjs-font-size, 16px))));
  font-family: $font-pagetitle-family;
  font-weight: $font-pagetitle-weight;
  font-size: var(--page-title-font-size);
  color: $font-pagetitle-color;
  position: static;
  line-height: multiply(1.33, var(--page-title-font-size));
  margin: 0 0 calcSize(0.5) 0px;
}

@mixin cover_title {
  --cover-title-font-size: var(--sjs-font-surveytitle-size, calc(2 * (var(--sjs-font-size, 16px))));
  font-size: var(--cover-title-font-size);
  line-height: multiply(1.25, var(--cover-title-font-size));
}

@mixin page_description {
  font-family: $font-pagedescription-family;
  font-weight: $font-pagedescription-weight;
  font-size: $font-pagedescription-size;
  color: $font-pagedescription-color;
  position: static;
  line-height: multiply(1.5, $font-pagedescription-size);
  margin: 0 0 calcSize(0.5) 0;
}

@mixin num_inline {
  float: none;
  margin-inline-start: 0;
  width: auto;
  padding-inline-start: 0;
  padding-inline-end: 0;

  &+span {
    float: none;
    width: auto;
  }
}