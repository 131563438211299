.sd-body {
  width: 100%;

  .sd-body__page {
    min-width: min(100%, 300px);
  }

  .sd-body__timer {
    padding: calcSize(2) calcSize(2) 0;
    box-sizing: border-box;
  }

  &.sd-body--static {
    max-width: calcSize(90);
    margin-left: auto;
    margin-right: auto;
    padding-top: calcSize(6);
    padding-bottom: calcSize(10);

    .sd-body__timer,
    .sd-body__navigation,
    .sd-body__page {
      margin-left: 0;
      margin-right: 0;
    }

    .sd-body__navigation {
      padding-top: calcSize(4);
    }

    .sd-body__navigation.sd-action-bar {
      padding-left: calcSize(5);
      padding-right: calcSize(5);
    }
  }

  &.sd-body--responsive {
    max-width: initial;
    padding: calcSize(5) calcSize(5) calcSize(3);
    box-sizing: border-box;

    .sd-page {
      padding: 0;
    }

    .sd-body__timer,
    .sd-body__navigation {
      padding: calcSize(2) 0;
    }

    .sd-body__navigation {
      padding-top: calcSize(4);
    }

    &.sd-body--with-timer {
      max-width: calc(100% + var(--sd-timer-size) * (-1 - 64 / 144) + 6 * #{$base-unit});
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.sd-root-modern--mobile {
  .sd-body {

    &.sd-body--with-timer.sd-body--responsive {
      max-width: initial;
      margin-left: 0;
      margin-right: 0;
    }

    &.sd-body--responsive,
    &.sd-body--static {
      padding: calcSize(3);

      .sd-body__navigation {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
      }
    }
  }
}

.sd-root--compact {
  .sd-body {

    &.sd-body--responsive,
    &.sd-body--static {

      .sd-body__navigation {
        padding-top: calcSize(7);
      }
    }
  }
}

.sd-body__navigation.sd-action-bar {
  box-sizing: border-box;
  padding: calcSize(2) calcSize(2);
  flex-wrap: wrap;
  gap: calcSize(2);
}

.sd-body--empty {
  min-height: 400px;
  text-align: center;
  padding-top: 180px;
  box-sizing: border-box;
}

.sd-body--empty,
.sd-body--loading {
  h1 {
    @include articleXXLargeFont;
  }

  h2 {
    @include articleXLargeFont;
  }

  h3 {
    @include articleLargeFont;
  }

  h4,
  h5,
  h6 {
    @include articleMediumFont;
  }

  td,
  span,
  div,
  p {
    @include articleDefaultFont;
  }

  a {
    color: $primary;
  }

  button {
    display: flex;
    align-items: center;
    padding: calcSize(1.5) calcSize(4);
    vertical-align: baseline;
    text-align: center;
    background-color: $background;
    box-shadow: $shadow-small;
    border: none;
    border-radius: calcSize(0.5);
    cursor: pointer;
    user-select: none;
    outline: solid calcSize(0.25) transparent;

    &:hover {
      background-color: $background-dark;
    }

    &:focus {
      box-shadow: 0 0 0 2px $primary;
    }

    span {
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: center;
      color: $primary;
      font-weight: 600;
      font-style: normal;
      font-family: var(--font-family, $font-family);
      font-size: $font-questiontitle-size;
      line-height: multiply(1.5, $font-questiontitle-size);
    }
  }
}

.sd-root_background-image {
  background-position-x: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}