/*----------------------------------------*\
	#UTILITIES
\*----------------------------------------*/

/*	#SCROLLBAR
	====================================================================== */

/* *
 * General
 */

.u-scrollbar::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
}

.u-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}

.u-scrollbar::-webkit-scrollbar-corner {
    background-color: transparent;
}

.u-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 100rem;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    /* theme.palette.common.secondaryHover */
    background-color: #c4c4c4;
}

.u-scrollbar::-webkit-scrollbar-thumb:hover {
    /* theme.palette.secondary.main */
    background-color: #58585a;
}

/* General Scrollbar Styling for Firefox */

/* *
 * General - Light
 */

/* .u-scrollbar-light::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
}

.u-scrollbar-light::-webkit-scrollbar-track {
    background-color: transparent;
}

.u-scrollbar-light::-webkit-scrollbar-corner {
    background-color: transparent;
}

.u-scrollbar-light::-webkit-scrollbar-thumb {
    border-radius: 100rem;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: var(--theme-bg-gray-light);
}

.u-scrollbar-light::-webkit-scrollbar-thumb:hover {
    background-color: var(--theme-bg-gray);
} */

/* *
 * General - Pink
 */

.u-scrollbar-pink::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
}

.u-scrollbar-pink::-webkit-scrollbar-track {
    background-color: transparent;
}

.u-scrollbar-pink::-webkit-scrollbar-corner {
    background-color: transparent;
}

.u-scrollbar-pink::-webkit-scrollbar-thumb {
    border-radius: 100rem;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    /* common.ung_pink */
    background-color: #f29fb3;
}

.u-scrollbar-pink::-webkit-scrollbar-thumb:hover {
    /* common.fuschia80 */
    background-color: #f178b6;
}
