.svc-question__dropdown-choices {
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-question__dropdown-choice--collapsed:nth-last-child(1) {
  opacity: 0.25;
}

.svc-question__dropdown-choice--collapsed:nth-last-child(2) {
  opacity: 0.5;
}

.svc-question__dropdown-choice--collapsed:nth-last-child(3) {
  opacity: 0.75;
}

.svc-question__dropdown-choices--wrapper .svc-action-button {
  margin-left: calc(6.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-top: var(--sjs-base-unit, var(--base-unit, 8px));
  cursor: pointer;
}