@import url('./assets/styles/main.css');

html,
body {
    min-height: 100vh;
    height: 100%;
    padding: 0;
    margin: 0;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
    width: 1rem;
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
    background-color: transparent;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
    border-radius: 100rem;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    /* theme.palette.common.secondaryHover */
    background-color: #c4c4c4;
}

html::-webkit-scrollbar-thumb:hover,
body::-webkit-scrollbar-thumb:hover {
    /* theme.palette.common.secondaryActive */
    background-color: #bfbfc3;
}

/* *
 * The root <div> element inside 'index.html' which is the entry point of the app.
 */
#root {
    height: 100%;
}
