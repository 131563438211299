.svc-designer-header .sd-container-modern__title {
  gap: 0;
  column-gap: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-logo-image {
  position: relative;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  padding: 0;
  cursor: pointer;
}
.svc-logo-image .svc-image-item-value-controls {
  display: block;
  top: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  right: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-logo-image__loading {
  width: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
  box-sizing: border-box;
  margin-right: calc(-2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-logo-image-placeholder {
  font-size: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  opacity: 0.25;
  white-space: nowrap;
  word-break: keep-all;
  padding: 0;
  width: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calc(-2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-logo-image-placeholder:hover {
  background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  opacity: unset;
}
.svc-logo-image-placeholder:hover use {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}
.svc-logo-image-placeholder svg {
  width: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-logo-image-placeholder use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.svc-logo-image-container {
  position: relative;
}
.svc-logo-image-container .sd-logo {
  margin: 0;
}

.svc-logo-image-controls {
  opacity: 0;
  width: max-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.svc-logo-image-container {
  cursor: default;
}

.svc-logo-image-container:not(.svc-logo-image-container--editable) .svc-logo-image-controls {
  display: none;
}

.svc-logo-image-container--editable:focus-within .svc-logo-image-controls,
.svc-logo-image-container--editable:hover .svc-logo-image-controls {
  opacity: 1;
}