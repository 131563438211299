.svd-test-results {
  font-family: var(--font-family);
  border-radius: 4px;
  box-shadow: inset 0px 1px 0px 0px var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
  padding: calc(12 * var(--sjs-base-unit, var(--base-unit, 8px)));
}
.svd-test-results .svd-test-results__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  border-bottom: 2px solid var(--sjs-border-light, var(--border-light, #eaeaea));
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
}
.svd-test-results .svd-test-results__header .svd-test-results__header-text {
  margin: 0;
}
.svd-test-results .svd-test-results__header .svd-test-results__header-types {
  display: flex;
  gap: var(--sjs-base-unit, var(--base-unit, 8px));
}
.svd-test-results .svd-test-results__text {
  display: flex;
  padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: calc(100% - 6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  overflow: auto;
  user-select: text;
  white-space: pre-wrap;
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
}
.svd-test-results .svd-test-results__table {
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  width: 100%;
  box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
}
.svd-test-results .svd-test-results__table table {
  width: 100%;
  border-collapse: collapse;
}
.svd-test-results .svd-test-results__table table .svd-test-results__marker {
  position: absolute;
  top: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svd-test-results .svd-test-results__table table .svd-test-results__marker .sv-svg-icon {
  transition: transform 0.2s ease-in-out;
}
.svd-test-results .svd-test-results__table table .svd-test-results__marker .sv-svg-icon use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}
.svd-test-results .svd-test-results__table table .svd-test-results__marker.svd-test-results__marker--expanded .sv-svg-icon {
  transform-origin: center;
  transform: rotate(90deg);
}
.svd-test-results .svd-test-results__table table th {
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) var(--sjs-base-unit, var(--base-unit, 8px));
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  text-align: left;
  font-weight: 600;
  font-size: 12px;
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}
.svd-test-results .svd-test-results__table table th:nth-child(1) {
  padding-left: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svd-test-results .svd-test-results__table table td {
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) var(--sjs-base-unit, var(--base-unit, 8px));
  max-width: calc(50 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  overflow: auto;
  border-top: 2px solid var(--sjs-border-light, var(--border-light, #eaeaea));
  user-select: text;
}
.svd-test-results .svd-test-results__table table td:nth-child(1) {
  position: relative;
  width: 50%;
  font-weight: 600;
}
.svd-test-results .svd-test-results__table table td:nth-child(2) {
  width: 50%;
  padding-right: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svd-test-results .svd-test-results__table table .survey-result-value {
  word-break: break-all;
}
.svd-test-results .svd-test-results__table table tr {
  vertical-align: top;
}
.svd-test-results .svd-test-results__table table tr .svd-test-results__node-value {
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}