@import "../variables.scss";

.svc-image-question-controls {
  position: absolute;
  top: calcSize(5);
  right: calcSize(1);
  display: none;
}

.svc-question__content--selected .svc-image-question-controls {
  display: flex;
}

.svc-question__content--image:not(.svc-question__content--loading):not(.svc-question__content--empty) {
  padding: calcSize(4) 0 calcSize(8) 0;

  .sd-image__image {
    border-radius: 0;
  }
}

.svc-question__content--image.svc-question__content--empty .sd-file,
.svc-question__content--image.svc-question__content--empty sv-ng-file-question {
  width: 100%;
}

.svc-question__content--image.svc-question__content--empty .sd-question--image {
  display: none;
}

.svc-question__content--image .sd-file__choose-btn--text {
  .sv-svg-icon {
    display: none;
  }
}

.svc-question__content--loading {
  .sd-image {
    display: none;
  }
}

.svc-image-question__loading-placeholder {
  position: relative;
  background-color: $background;
  width: 100%;
  height: calcSize(27.5);
  border: 1px dashed $border-inside;
}

.svc-image-question__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}