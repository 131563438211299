/*	Editor classic
	====================================================================== */

.EditorClassic .ck-editor {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    height: 100%;
    min-height: 0;
    max-height: 100%;
}

.EditorClassic .ck-editor .ck-editor__top {
    flex: 0 0 auto;
}

.EditorClassic .ck-editor .ck-editor__main {
    flex: 1 0 0;

    min-height: 0;
}

.EditorClassic .ck-content.ck-editor__editable {
    height: 100%;
}

/*	Editor inline
	====================================================================== */

.EditorInline .ck-content.ck-editor__editable {
    height: 100%;
}

.dropdown-right .ck-reset {
    left: auto !important;
    right: 0 !important;
    top: -50px !important;
}

.dropdown-left .ck-reset {
    left: 0 !important;
    right: auto !important;
    top: -50px !important;
}

/*	Read-only mode
	====================================================================== */

.ck-editor .ck-read-only {
    /* 
		Removes outlines from widgets while they are selected.
	 */
    --ck-widget-outline-thickness: 0;
}

/* *
 * Scrollbar
 */

.ck-content::-webkit-scrollbar {
    width: 1rem;
}

.ck-content::-webkit-scrollbar-track {
    background-color: transparent;
}

.ck-content::-webkit-scrollbar-corner {
    background-color: transparent;
}

.ck-content::-webkit-scrollbar-thumb {
    border-radius: 100rem;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    /* theme.palette.common.secondaryHover */
    background-color: #c4c4c4;
}

.ck-content::-webkit-scrollbar-thumb:hover {
    /* theme.palette.secondary.main */
    background-color: #58585a;
}

.ck-balloon-panel {
    z-index: 999999 !important; /* Ensure it is higher than the modal */
}
