.sv-cover {
  position: relative;
  background-color: $cover-backcolor;
}

.sv-conver__overlap {
  margin-bottom: calcSize(-14);
  padding-bottom: calcSize(8);
}

.sv-conver__overlap.sv-conver__without-background {
  margin-bottom: 0;
  padding-bottom: 0;
}

.sv-conver__without-background .sv-conver__content {
  padding-bottom: 0;
}

.sv-conver__content {
  padding: calcSize(5);
  box-sizing: border-box;
  height: 100%;
  position: relative;
  display: grid;
  gap: 0;
  // grid-auto-rows: min-content;
  grid-auto-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr 1fr 1fr;
}

.sv-conver__content--static {
  max-width: calcSize(88);
  margin-left: auto;
  margin-right: auto;
}

.sv-cover__background-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  background-position-x: center;
}

.sv-cover__background-image--contain {
  background-repeat: no-repeat;
}

.sv-cover__cell {
  position: relative;
}

.sv-cover__cell-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: max-content;
  top: 0;
  bottom: 0;
}

.sv-cover__cell--left {
  .sv-cover__cell-content {
    left: 0;
  }
}

.sv-cover__cell--center {
  .sv-cover__cell-content {
    min-width: 100%;
  }
}

.sv-cover__cell--right {
  .sv-cover__cell-content {
    right: 0;
  }
}

.sv-cover__logo {
  display: flex;
}

.sv-cover__title {
  display: flex;
}

.sv-cover__title .sd-title {
  @include cover_title;
  margin: 0;
  color: $cover-title-forecolor;
  font-family: $font-surveytitle-family;
  font-weight: $font-surveytitle-weight;
}

.sv-cover__logo ~ .sv-cover__title {
  margin-top: calcSize(4);
}

.sv-cover__logo ~ .sv-cover__description {
  margin-top: calcSize(4);
}

.sv-cover__title ~ .sv-cover__description {
  margin-top: calcSize(1);
}

.sv-cover__description {
  display: flex;
}

.sv-cover__description .sd-description {
  margin: 0;
  color: $cover-description-forecolor;
}
.sv-conver__content .sd-header__text h3 {
  margin: 0;
}
