$font-family: var(--font-family);

.sv_progress-toc {
  padding: calcSize(1);
  max-width: calcSize(42);
  height: 100%;
  background: $background;
  box-sizing: border-box;
  min-width: calcSize(32);

  .sv-list__item.sv-list__item--selected .sv-list__item-body {
    background: $primary-light;
    color: $foreground;
    font-weight: 400;
  }

  .sv-list__item span {
    white-space: break-spaces;
  }

  .sv-list__item-body {
    padding-inline-start: calcSize(2);
    padding-inline-end: calcSize(2);
    border-radius: calcCornerRadius(1);
    padding-top: calcSize(1.5);
    padding-bottom: calcSize(1.5);
  }
}

.sv_progress-toc--left {
  border-right: 1px solid $border;
}

.sv_progress-toc--right {
  border-left: 1px solid $border;
}

.sv_progress-toc--mobile {
  position: fixed;
  top: calcSize(3);
  right: calcSize(4);
  width: auto;
  min-width: auto;
  height: auto;
  background-color: $background-dim;
  z-index: 15;
  border-radius: calcSize(3);

  &>div {
    width: calcSize(3);
    height: calcSize(3);
  }

  &:hover {
    background-color: $background-dim;
  }
}