#eXnkhpmUtZM2_tr {
    animation: eXnkhpmUtZM2_tr__tr 2400ms linear infinite normal forwards;
}
@keyframes eXnkhpmUtZM2_tr__tr {
    0% {
        transform: translate(75.125099px, 74px) rotate(2340deg);
        animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
    }
    25% {
        transform: translate(75.125099px, 74px) rotate(2430deg);
        animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
    }
    50% {
        transform: translate(75.125099px, 74px) rotate(2520deg);
        animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
    }
    75% {
        transform: translate(75.125099px, 74px) rotate(2610deg);
        animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
    }
    100% {
        transform: translate(75.125099px, 74px) rotate(2700deg);
    }
}
