svc-tab-json-editor-textarea {
  width: 100%;
  height: 100%;
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.svc-json-editor-tab__content {
  position: relative;
  height: 100%;
}

.svc-json-editor-tab__content-area {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

.svc-json-editor-tab__errros_button {
  position: absolute;
  top: 0%;
  right: calc(0% + 2 * var(--sjs-base-unit, var(--base-unit, 8px)));
  min-height: calc(2.5 * var(--sjs-base-unit, var(--base-unit, 8px)));
  max-height: calc(2.5 * var(--sjs-base-unit, var(--base-unit, 8px)));
  border: none;
  background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
  font-weight: bold;
}

.svc-json-editor-tab__content-errors {
  position: absolute;
  right: 0%;
  top: calc(0% + 3 * var(--sjs-base-unit, var(--base-unit, 8px)));
  max-width: calc(100% - 2 * var(--sjs-base-unit, var(--base-unit, 8px)));
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1));
  border: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
  border-radius: 1px;
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  font-family: monospace;
}
.svc-json-editor-tab__content-errors span {
  white-space: pre-line;
}
.svc-json-editor-tab__content-errors span > b {
  color: var(--sjs-special-red, var(--red, #e60a3e));
}

.svc-json-editor-tab__content-errors:empty {
  display: none;
}

.svc-json-errors {
  display: flex;
  max-height: calc(var(--sjs-base-unit, var(--base-unit, 8px)) * 25 + 5px);
  overflow-y: auto;
  flex-direction: column;
  padding: 0;
  margin: 0;
  border-top: 1px solid var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
}

.svc-json-errors__item {
  pointer-events: none;
  display: block;
}

.svc-json-error {
  font-family: var(--font-family);
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) var(--sjs-base-unit, var(--base-unit, 8px)) var(--sjs-base-unit, var(--base-unit, 8px)) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
  display: flex;
  align-items: center;
  gap: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-json-errors__item:not(:first-of-type) .svc-json-error {
  border-top: 1px solid var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
}

.svc-json-error .sv-string-viewer {
  pointer-events: all;
  cursor: pointer;
}

.svc-json-error .sv-string-viewer:hover {
  text-decoration: underline;
}

.svc-json-error__icon {
  fill: var(--sjs-special-red, var(--red, #e60a3e));
}

.svc-json-error__container {
  display: flex;
  gap: var(--sjs-base-unit, var(--base-unit, 8px));
}

.svc-json-error__title {
  flex: 1 1 auto;
}

.svc-json-error__fix-button {
  appearance: none;
  flex-grow: 0;
  flex-shrink: 0;
  pointer-events: all;
  padding: 0 var(--sjs-base-unit, var(--base-unit, 8px));
  width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background: var(--sjs-general-backcolor, var(--background, #fff));
  border: none;
  border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
  cursor: pointer;
}
.svc-json-error__fix-button svg {
  fill: var(--sjs-general-dim-forecolor-light, rgba(0, 0, 0, 0.45));
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-json-error__fix-button:hover svg {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}