.svc-property-panel__group {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border: none;
  outline: none;
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  cursor: pointer;
  text-align: left;
  background: var(--sjs-general-backcolor, var(--background, #fff));
  box-shadow: inset 0px -1px 0px var(--sjs-border-default, var(--border, #d6d6d6));
  font-family: var(--font-family);
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-property-panel__group:disabled,
.svc-property-panel__group:disabled:hover {
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  opacity: 0.25;
  cursor: default;
}

.svc-property-panel__group:hover {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  font-weight: 400;
}

.svc-property-panel__group--active,
.svc-property-panel__group--active:hover {
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  font-weight: 600;
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
}