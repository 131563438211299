.spg-theme-builder-root {
  .spg-paneldynamic__separator {
    border: none;
    margin: 0;
    height: calcSize(2);
  }
  .spg-panel__footer {
    margin: calcSize(1) auto 0;
  }
  .spg-paneldynamic__add-btn {
    margin: 0 auto;
  }

  .spg-row--multiple {
    .spg-spin-editor {
      .spg-input-container__buttons-container {
        display: none;
      }
    }
    .spg-question__header--location--left {
      min-width: auto;
    }
    & > div {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: calc(50% - #{$base-unit} * 0.5);
      min-width: auto;
      input {
        min-width: 0;
      }
      .spg-question--location--left {
        height: calcSize(6);
        box-sizing: border-box;
        .spg-input {
          height: auto;
        }
      }
    }
  }
  .sv-button-group {
    overflow: hidden;
  }
  .spg-question__header--location--left {
    width: max-content;
    flex: 0 0;
    .spg-question__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  //2 level nested
  .spg-panel__content .spg-panel__content {
    padding: 0;
    padding-bottom: calcSize(5);
    box-shadow: none;
    .spg-panel__title {
      background-color: transparent;
      box-shadow: none;
      padding: calcSize(1) 0;
    }
    &::after {
      content: " ";
      display: block;
      position: relative;
      left: calcSize(-4);
      top: calc(5 * #{$base-unit});
      width: calc(8 * #{$base-unit} + 100%);
      height: 1px;
      background-color: $border;
    }
    & > .spg-row:first-of-type {
      margin-top: 0;
    }
  }
  //3 level nested
  .spg-panel__content .spg-panel__content {
    .spg-panel__content {
      padding-bottom: 0;
      &::after {
        content: none;
      }
      .spg-row {
        margin-top: calcSize(1);
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
  .spg-panel__content {
    .spg-row:last-of-type {
      .spg-panel__content {
        padding-bottom: 0;
        &::after {
          content: none;
        }
      }
    }
  }
}

.sv-mobile-side-bar .spg-theme-builder-root {
  .spg-panel__content .spg-panel__content::after {
    width: calc(4 * var(--sjs-base-unit, var(--base-unit, 8px)) + 100%);
    left: calc(-2 * var(--sjs-base-unit, var(--base-unit, 8px)));
  }
}
